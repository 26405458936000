body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.hwkey-raw {
	font-size: 10px;
    line-break: anywhere;
    opacity: 0.5;
	display: inline-block;
	border-top: 1px solid;
    margin-top: 10px;
    padding-top: 10px;
}

.hwkey-raw:hover {
    opacity: 1;
}

.hwkey-decoded {
	white-space: pre;
	font-family: monospace;
    font-size: 12px;
}

.license-box {
	line-break: anywhere;
	font-family: monospace;
	background: #2196f3;
	display: block;
	padding: 10px;
	border: 1px solid white;
	border-radius: 5px;
	color: white;
	box-shadow: 0px 2px 10px rgba(0,0,0,0.5);
	position: relative;
	overflow: hidden;
}

.license-cool-icon {
    position: absolute;
    opacity: 0.15;
    transform: scale(5);
    top: 100px;
    left: 80px;
}

.license-tools {
	padding: 20px;
	text-align: center;
}

.license-tools button {
    margin: 0px 10px;
    padding: 10px 15px;
}

.license-edit-warning {
	background: #FE0;
    border: 3px solid;
    box-shadow: 0px 0px 5px;
    padding: 10px;
	overflow: hidden;
}
.license-edit-warning .warn-ico {
	display: inline-block;
    float: left;
    margin-right: 5px;
    transform: scale(5);
    width: 130px;
    height: 130px;
    transform-origin: top left;
}

.license-edit-warning h1 {
	margin: 10px 0px;
}

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
